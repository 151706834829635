// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import get from 'lodash/get'
import UAParser from 'ua-parser-js'
import parse from 'qs/lib/parse'

export const CODECS = {
  VP8: 'vp8', // Sets the browser to use VP8 for encoding and decoding
  H264: 'h264', // Sets the browser to use H264 for encoding and decoding
}

const parser = new UAParser()

const userAgentInfo = parser.getResult()

/**
 * The Agora RTC Web SDK supports
 * screen sharing in the following desktop browsers:
  - Chrome 58 or later
  - Firefox 56 or later
  - Edge 80 or later on Windows 10+
 */

export const isSafari = () => userAgentInfo.browser.name === 'Safari' || userAgentInfo.browser.name === 'Mobile Safari'

export const isChrome = () => userAgentInfo.browser.name === 'Chrome'
export const isFirefox = () => userAgentInfo.browser.name === 'Firefox'

export const isCompatibleChrome = () => {
  if (isChrome()) {
    if (Number(userAgentInfo.browser.major) >= 58) return true
  }
  return false
}

// create function get difference minutes from 2 date string and handle null value
export const diffMinutes = (date1, date2) => {
  if (!date1 || !date2) return 0
  const diff = new Date(date1) - new Date(date2)
  return Math.floor(diff / 1000 / 60)
}

export const isVideo = value => {
  const extensions = ['.webm', '.mkv', '.flv', '.flv', '.vob', '.ogv', '.ogg', '.drc', '.mng', '.avi', '.MTS', '.M2TS', '.TS', '.mov', '.qt', '.wmv', '.yuv', '.rm', '.rmvb', '.viv', '.asf', '.amv', '.mp4', '.m4p', '.m4v', '.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mpg', '.mpeg', '.m2v', '.m4v', '.svi', '.3gp', '.3g2', '.mxf', '.roq', '.nsv', '.flv', '.f4v', '.f4p', '.f4a', '.f4b']
  const ext = value.split('.').pop()
  return extensions.includes(`.${ext}`)
}

export const isCompatibleFirefox = () => {
  if (isFirefox()) {
    if (Number(userAgentInfo.browser.major) >= 56) return true
  }
  return false
}

export const safeJsonParse = (strValue, fallbackValue) => {
  try {
    return JSON.parse(strValue)
  } catch (error) {
    return fallbackValue
  }
}

export const showError = (error, fallbackMessage) => {
  const vm = new Vue({})
  const errorObj = get(error, 'response.data.errors.errors') || {}
  const errorMsg = get(error, 'response.data.errors.message') || get(error, 'response.data.message') || get(error, 'message')
  const errors = Object.values(errorObj).join('; ')
  const massage = error.fallbackMessage || fallbackMessage
  vm.$message.warn(errors || errorMsg || massage, 3)
}
export const getErrorResponseMessage = error => {
  const errorObj = get(error, 'response.data.errors.errors') || {}
  const errorMsg = get(error, 'response.data.errors.message') || get(error, 'message')
  const errors = Object.values(errorObj).join('; ')
  return errors || errorMsg
}

export const ORDER_ID_REGEX = /\borders [0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/

export const UUID_REGEX = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

export const isOrderMessage = message => {
  if (!message || typeof message !== 'string') return false
  const arr = message.split(' ')
  if (arr.length === 3 || arr.length === 4) {
    const orderID = arr[1]
    return arr[0] === '/orders' && UUID_REGEX.test(orderID)
  }
  return false
}

export const SERVICE_ID_REGEX = /\bservices [0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/

export const isServiceMessage = message => SERVICE_ID_REGEX.test(message)

export const EMOJI_ID_REGEX = /\bemojis [0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\b/

export const CALL_SESSION_REGEX = /(^\/calling) [a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12} (calling|end)$/

export const isEmojiMessage = message => EMOJI_ID_REGEX.test(message)

export const getOrderID = message => (isOrderMessage(message) ? message.split(' ')[1] : null)

export const getServiceID = message => (isServiceMessage(message) ? message.split('/services ')[1] : null)

export const getEmojiID = message => (isEmojiMessage(message) ? message.split('/emojis ')[1] : null)

export const ORDER_STATUSES = {
  VERIFYING: 'verifying',
  CREATED: 'created',
  ACCEPTED: 'accepted',
  STARTED: 'started',
  START_PENDING: 'start_pending',
  START_CANCELLED: 'start_cancelled',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DONE: 'done',
}

export const SELLER_ORDER_MESSAGES = {
  VERIFYING: 'sentOrder',
  CREATED: 'orderCreated',
  ACCEPTED: 'isUserAcceptedOrder',
  START_PENDING: 'startPendingOrder',
  START_CANCELLED: 'startCancelledOrder',
  STARTED: 'startSessionNotice',
  COMPLETED: 'completedOrder',
  CANCELLED: 'isUserCancelledOrder',
}
export const BUYER_ORDER_MESSAGES = {
  CREATED: 'orderCreated',
  ACCEPTED: 'acceptedOrder',
  START_PENDING: 'isUserStartPendingOrder',
  START_CANCELLED: 'startCancelledOrder',
  STARTED: 'startSessionNotice',
  COMPLETED: 'completedOrder',
  CANCELLED: 'isUserCancelledOrder',
}

export const PAYMENT_METHOD_TYPES = {
  CARD: 'card',
  GRABPAY: 'grabpay',
  IPAYMU: 'ipaymu',
  GCASH: 'manual',
  STRIPE_WECHAT_PAY: 'stripe_wechat_pay',
  EARNINGS: 'earnings',
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
  STRIPE_PAYNOW: 'stripe_paynow',
  SENANGPAY: 'senangpay',
  DRAGONPAY_GCASH: 'dragonpay_gcash',
  XENDIT: 'xendit',
  GOPAY: 'midtransV2_gopay',
  GOOGLEPLAY: 'googlePlay',
  GOLD: 'gold',
  OMISE_LINEYPAY: 'omise_rabbit_linepay',
  OMISE_TRUEMONEY: 'omise_truemoney',
  MIDTRANS: 'midtrans',
  ZALOPAY_WALLET: 'zalopay_wallet',
  ZALOPAY_VIETQR: 'zalopay_vietqr',
  ZALOPAY_LOCALBANK: 'zalopay_localbank',
  PEPAY_LINEPAY: 'pepay_linepay',
  PEPAY_JKO: 'pepay_jko',
  PEPAY_CONVENIENCE_STORE: 'pepay_convenience_store',
  PEPAY_CONVENIENCE_STORE_IBON: 'pepay_convenience_store_ibon',
  PEPAY_CONVENIENCE_STORE_MBC: 'pepay_convenience_store_mbc',
  PEPAY_CONVENIENCE_STORE_FAMI: 'pepay_convenience_store_fami',
  PEPAY_ATM: 'pepay_atm',
  PEPAY_ATM_CTCB: 'pepay_atm_ctcb',
  PEPAY_ATM_HNCB: 'pepay_atm_hncb',
  PEPAY_ATM_SCSB: 'pepay_atm_scsb',
  XENDIT_CREDIT_CARD: 'xendit_credit_card',
  XENDIT_INTERNET_BANKING: 'xendit_internet_banking',
  XENDIT_DANA: 'xendit_dana',
  XENDIT_OVO: 'xendit_ovo',
  XENDIT_QRIS: 'xendit_qris',
  XENDIT_MY_WECHATPAY: 'xendit_my_wechatpay',
  SIAMPAY_TRUEMONEY: 'siampay_truemoney',
  SIAMPAY_QR_PROMPTPAY: 'siampay_qr_promptpay',
  DRAGONPAY_PAYMAYA: 'dragonpay_paymaya',
  STRIPE_GOOGLE_PLAY: 'stripe_google_play',
  STRIPE_APPLEPAY: 'stripe_applepay',
  ELEMENTPAY: 'elementpay',
  HITPAY_CARD: 'hitpay_card',
  HITPAY_SG_PAYNOW_ONLINE: 'hitpay_sg_paynow_online',
  HITPAY_MY_DUITNOW: 'hitpay_my_duitnow',
  HITPAY_AU_PAY_ID: 'hitpay_au_pay_id',
  HITPAY_IN_UPI_QR: 'hitpay_in_upi_qr',
  HITPAY_MY_MYBANK_QR: 'hitpay_my_mybank_qr',
  HITPAY_VN_VIET_QR_PAYME: 'hitpay_vn_viet_qr_payme',
  HITPAY_PH_QRPH: 'hitpay_ph_qrph',
}

export const linkify = (inputText = '', userID = '') => {
  let replacedText = ''
  const isPaddie = userID === process.env.NUXT_ENV_PADDIE_ID

  if (isPaddie) {
    // Replace sanitized symbols with symbols
    replacedText = inputText
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#34;/g, '"')
  } else { // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
    replacedText = inputText.replace(
      replacePattern1,
      '<a href="$1" target="_blank" rel="noopener noreferrer ugc">$1</a>',
    )

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank" rel="noopener noreferrer ugc">$2</a>',
    )
  }

  // Change email addresses to mailto:: links.
  const replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

  return replacedText
}

export const checkCompatible = () => new Promise(resolve => {
  if (isFirefox() && !isCompatibleFirefox()) {
    showError(
      new Error(
        'Failed to start screen sharing, maybe the current version of firefox not support, please upgrade your firefox to latest version.',
      ),
    )
    return resolve(false)
  }
  if (isChrome() && !isCompatibleChrome()) {
    showError(
      new Error(
        'Failed to start screen sharing, maybe current version of chrome not support, please upgrade your chrome to latest version.',
      ),
    )
    return resolve(false)
  }
  return resolve(true)
})

export const isVoiceCallStream = streamId => streamId < 2000

export const isShareScreenStream = streamId => streamId >= 2000 && streamId < 3000

export const isViewShareScreenStream = streamId => streamId >= 3000

export const socialData = [
  {
    key: 'facebook',
    url: 'https://www.facebook.com/ganknow/',
    icon: 'facebook-circle-light.svg',
  },
  {
    key: 'instagram',
    url: 'https://www.instagram.com/ganknow/',
    icon: 'instagram-circle-light.svg',
  },
  {
    key: 'twitter',
    url: 'https://twitter.com/Ganknow',
    icon: 'twitter-circle-light.svg',
  },
  {
    key: 'tiktok',
    url: 'https://tiktok.com/@gank.now',
    icon: 'tiktok-circle-light.svg',
  },
  {
    key: 'discord',
    url: 'https://discord.gg/gank-745842194962841611',
    icon: 'discord-circle-light.svg',
  },
  {
    key: 'youtube',
    url: 'https://www.youtube.com/@gankstudios',
    icon: 'youtube-circle-light.svg',
  },
]

export const reportTypes = [
  {
    id: 'offensive',
    text: 'offensive',
  },
  {
    id: 'spam',
    text: 'spam',
  },
  {
    id: 'fake_account',
    text: 'fakeAccount',
  },
  {
    id: 'privacy_violation',
    text: 'privacyViolation',
  },
  {
    id: 'order_dispute',
    text: 'orderDispute',
  },
  {
    id: 'others',
    text: 'others',
  },
]

export function isYoutubeUrl(url) {
  return /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(url)
}

export function isVimeoUrl(url) {
  return /^(https?:\/\/)?(www\.vimeo\.com|vimeo\.?com|player\.vimeo\.com)\/.+$/.test(url)
}

export function getYoutubeThumbnail(url) {
  if (typeof url !== 'string') return null
  let id = ''
  if (url.includes('youtu.be')) {
    // eslint-disable-next-line prefer-destructuring
    id = url.split('youtu.be/')[1]
    id = id.includes('?') ? id.split('?')[0] : id
  } else {
    id = parse(url.split('?')[1]).v
  }
  // high quality
  return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
}

export const getVimeoID = url => {
  const urls = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)
  const id = urls[3]
  let userId = urls[4]
  if (typeof userId !== 'undefined') {
    userId = userId.replace(/\//g, '')
  }
  return `${id}?h=${userId}&`
}

export const getYoutubeID = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const radarChartOptions = {
  chart: {
    type: 'radar',
    fontFamily: 'Noto Sans',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColors: '#242734',
        connectorWidth: 3,
        connectorColors: '#242734',
        fill: {
          colors: ['#262939', '#393c4e', '#4b4e61'],
        },
      },
    },
  },
  legend: { show: false },
  colors: ['#FF4560'],
  markers: {
    size: 3,
    colors: ['#FF4560'],
    strokeColor: '#FF4560',
    strokeWidth: 2,
  },
  yaxis: {
    show: false,
  },
}

export function formatMoney(amount, locale, currency) {
  const langCode = locale === 'MY' ? 'MS' : locale
  let decimalDigits = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

  if (currency === 'IDR' || currency === 'idr' || currency === 'VND' || currency === 'vnd') {
    decimalDigits = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  }
  const formatter = new Intl.NumberFormat(langCode || 'en-US', {
    style: 'currency',
    currency: currency || 'USD',
    ...decimalDigits,
  })

  let formatedMoney = formatter.format(amount)

  if (!currency && !locale) {
    const dollarFormatRegex = /^\$\d+(\.\d+)?$/
    if (dollarFormatRegex.test(formatedMoney)) {
      formatedMoney = formatedMoney.replace(/^\$/, 'US$ ')
    }
  }

  return formatedMoney
}

export function noMinFractionUSD(value) {
  if (typeof value !== 'number') {
    return value // Return the original value if it's not a number
  }

  // Convert the number to a string and split it into whole and fractional parts
  const [whole, fraction] = value.toString().split('.')

  // Format the whole part with commas
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas for thousands

  // Determine the formatted value
  let formatted = `US$ ${formattedWhole}`

  // If there is a fraction, add it (up to 2 decimal places)
  if (fraction) {
    formatted += `.${fraction.slice(0, 2)}` // Limit to 2 decimal places
  }

  return formatted
}

export function basePropertyOf(object) {
  return function (key) {
    return object == null ? undefined : object[key]
  }
}

export function escapeString(value) {
  const reEscapedHtml = /&(?:amp|lt|gt|quot|#39|#34);/g
  const reHasEscapedHtml = RegExp(reEscapedHtml.source)
  const toString = value == null ? '' : value
  const htmlUnescapes = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&#34;': '"',
  }
  const unescapeHtmlChar = basePropertyOf(htmlUnescapes)

  return toString && reHasEscapedHtml.test(toString)
    ? toString.replace(reEscapedHtml, unescapeHtmlChar)
    : toString
}

export function formatLocale(countryCode) {
  if (countryCode === 'ID') {
    return 'id-ID'
  }
  if (countryCode === 'MY') {
    return 'en-MY'
  }
  if (countryCode === 'VN') {
    return 'vi-VN'
  }
  if (countryCode === 'PH') {
    return 'en-PH'
  }
  if (countryCode === 'SG') {
    return 'en-US'
  }
  if (countryCode === 'TH') {
    return 'th-TH'
  }
  if (countryCode === 'TW') {
    return 'en-US'
  }
  return 'en-US'
}

export function formatLocaleCurrency(currency) {
  if (currency === 'IDR') {
    return 'id-ID'
  }
  if (currency === 'MYR') {
    return 'en-MY'
  }
  if (currency === 'VND') {
    return 'vi-VN'
  }
  if (currency === 'PHP') {
    return 'en-PH'
  }
  if (currency === 'SGD') {
    return 'en-US'
  }
  if (currency === 'THB') {
    return 'th-TH'
  }
  if (currency === 'TWD') {
    return 'en-US'
  }
  return 'en-US'
}

export const SEARCH_INDEX_TYPES = {
  USERS: 'users',
  SERVICES: 'services',
  GAMES: 'games',
}

export const toObject = (arr, key) => arr.reduce((acc, each, idx) => {
  if (!each) return acc
  if (key) {
    acc[each[key]] = each
  } else {
    acc[each] = idx
  }
  return acc
}, {})

export const getAttribute = (e, attr) => {
  const { currentTarget = document.body } = e
  let { target } = e
  while (target !== currentTarget) {
    if (target && target.hasAttribute(attr)) return target.getAttribute(attr)
    target = target.parentNode
  }
  if (target && target.hasAttribute(attr)) return target.getAttribute(attr)
  return null
}

export const nFormatter = (num, digits, locale = undefined) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]

  const number = parseInt(num, 10)

  const item = lookup
    .slice()
    .reverse()
    .find(data => number >= data.value)
  let formatNumber
  if (item && number >= 10000) {
    formatNumber = (number / item.value).toLocaleString(locale, {
      maximumFractionDigits: digits,
    }) + item.symbol
  } else {
    formatNumber = item && number < 10000
      ? number.toLocaleString(locale, {})
      : '0'
  }
  return formatNumber
}

export const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 MB'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const formatGB = kilobytes => {
  const k = 1000 ** 3
  return parseFloat(kilobytes / k)
}

export const getUserIDFromChannel = (channel, currentUserID) => {
  if (!channel) return undefined
  return channel
    .replace(/#/g, '')
    .split(',')
    .find(id => id !== currentUserID)
}

export const getPaymentString = payment => {
  switch (payment) {
    case 'pepay_linepay':
      return 'LinePay'
    case 'pepay_jko':
      return 'JKO'
    case 'pepay_convenience_store':
      return 'Convenience Store'
    case 'pepay_convenience_store_ibon':
      return 'IBON'
    case 'pepay_convenience_store_mbc':
      return 'IBON'
    case 'pepay_convenience_store_fami':
      return 'Fami'
    case 'pepay_atm':
      return 'ATM'
    case 'pepay_atm_ctcb':
      return '中國信託/CTCB'
    case 'pepay_atm_hncb':
      return '華南銀行/HNCB'
    case 'pepay_atm_scsb':
      return '上海銀行/SCSB'
    case 'xendit_credit_card':
      return 'Credit Card'
    case 'xendit_internet_banking':
      return 'Internet Banking'
    case 'xendit_dana':
      return 'Dana'
    case 'xendit_ovo':
      return 'OVO'
    case 'xendit_qris':
      return 'QRIS'
    case 'xendit_my_wechatpay':
      return 'WechatPay'
    case 'zalopay_wallet':
      return 'ZaloPay e-wallet'
    case 'zalopay_vietqr':
      return 'VietQR'
    case 'zalopay_localbank':
      return 'Thẻ nội địa Napas / Internet Banking'
    case 'hitpay_card':
      return 'Card Hitpay'
    case 'hitpay_sg_paynow_online':
      return 'Paynow'
    case 'hitpay_my_duitnow':
      return 'Duitnow'
    case 'hitpay_au_pay_id':
      return 'Pay ID'
    case 'hitpay_in_upi_qr':
      return 'UPI QR'
    case 'hitpay_my_mybank_qr':
      return 'Maybank QR'
    case 'hitpay_vn_viet_qr_payme':
      return 'VietQR'
    case 'hitpay_ph_qrph':
      return 'QRPH'
    case 'apple_store':
      return 'Apple Store'
    case 'stripe_paynow':
      return 'PayNow'
    case 'stripe_google_play':
      return 'Google Pay'
    case 'stripe_applepay':
      return 'Apple Pay'
    case 'dragonpay_gcash':
      return 'GCash'
    case 'dragonpay_paymaya':
      return 'Paymaya'
    case 'omise_rabbit_linepay':
      return 'Omise LINE Pay'
    case 'omise_truemoney':
      return 'Omise TrueMoney'
    case 'senangpay':
      return 'senangPay'
    case 'midtrans':
      return 'Midtrans'
    case 'ipaymu':
      return 'iPaymu'
    case 'xendit':
      return 'Xendit'
    case 'grabpay':
      return 'GrabPay'
    case 'midtransV2_gopay':
      return 'GoPay'
    case 'googlePlay':
      return 'Google Play'
    case 'stripe':
      return 'Stripe'
    case 'paypal':
      return 'Paypal / Credit Card / Debit Card'
    case 'gold':
      return 'Gold'
    case 'card':
      return 'Card'
    default:
      return payment
  }
}

export const isValidFloat = (amount, maxDecimal = 1) => {
  let amountUse = amount

  // validation
  if (!amountUse && amountUse !== 0) {
    return false
  }

  if (typeof amountUse !== 'number') {
    amountUse = parseFloat(amountUse)
  }

  // Formula
  // eslint-disable-next-line no-restricted-properties
  const multiplier = Math.pow(10, maxDecimal)
  const multipliedAmount = amountUse * multiplier
  const diff = multipliedAmount - Math.floor(multipliedAmount)

  // Result
  // Should include the last two condition to handle floating number issue
  return diff === 0 || diff > 0.999999 || diff < 0.000001
}

export const createUserBasedDeviceId = () => {
  const userData = {
    // System data
    screen: `${window.screen.width}x${window.screen.height}`,
    colorDepth: window.screen.colorDepth,
    pixelRatio: window.devicePixelRatio,
    platform: navigator.platform,

    // Browser data
    browser: navigator.userAgent,
    language: navigator.language,

    // Time-based data
    timezone: new Date().getTimezoneOffset(),
    timezoneRegion: Intl.DateTimeFormat().resolvedOptions().timeZone,

    // Additional browser capabilities
    cookiesEnabled: navigator.cookieEnabled,
    doNotTrack: navigator.doNotTrack,
    maxTouchPoints: navigator.maxTouchPoints,
  }

  // Create a unique string and hash it
  const dataString = Object.entries(userData)
    .map(([key, value]) => `${key}:${value}`)
    .join(';;')

  const hashCode = str => {
    let hash = 0
    for (let i = 0; i < str.length; i += 1) {
      // Use multiplication instead of bit shifting
      hash = Math.imul(31, hash) + str.charCodeAt(i)
      // Use modulo to keep numbers in a reasonable range
      hash %= 2147483647
    }
    return Math.abs(hash)
  }

  // Generate 16 character string using combination of letters and numbers
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let deviceId = ''

  for (let i = 0; i < 16; i += 1) {
    const hash = hashCode(dataString + i)
    const index = hash % charset.length
    deviceId += charset[index]
  }

  return deviceId
}

export const maskInput = input => {
  if (typeof input === 'string' && input.includes('@')) {
    // Mask email
    return input.replace(/(\w{3})[\w.-]*(@[\w.]+)/, '$1****$2')
  } if (typeof input === 'string' && /^\d+$/.test(input)) {
    // Mask number in string form
    return input.replace(/^\d+(?=\d{4}$)/, '******')
  }
  // Unsupported input
  return input
}
